.App {
  width: 100vw;
  height: 100vh;
  max-width: 1200px;
  margin: auto;
  background: rgba(239, 239, 239, 0.7);
}

.App-logo {
  pointer-events: none;
}

.logoBlue120x500 {
  position: absolute;
  height: 30px;
  width: 125px;
  align-self: center;
  top: 10px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 0.5s linear;
  }
}

.App-header {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #094375;
  text-align: center;
  color: #094375;
  padding-top: 5px;
}
.basketMenue {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 20px;
  height: 25px;
  cursor: pointer;
}
.loginMenue {
  position: absolute;
  background: rgb(255, 255, 255, 90%);
  left: 10px;
  top: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
form {
  position: relative;
  border: rgb(244, 244, 244, 0.5) 0.5px solid;
  width: 55%;
  margin: 5px auto 20px auto;
  transition: width 0.2s linear 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 0 0px 0 5px;
}

#searchBtn {
  display: block;
  right: -0.2px;
  width: 0.1px;
  height: 0.1px;
  background-color: transparent;
  border: none;
  transition: width 0.4s linear 0.08s;
  color: transparent;
  margin-top: 0;
}
.search:not(:placeholder-shown:empty) + #searchBtn {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  border: none;
  color: rgb(255, 255, 255);
  width: 30px;
  font-size: 1rem;
  background: #14a051;
  justify-content: center;
  text-align: center;
  padding: 0;
}

.search {
  padding: auto;
  border-radius: 50px;
  height: 1.5rem;
  width: 100%;
  outline: 0;
  background: transparent;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  border: none;
  flex-grow: 2;
  color: rgb(218, 218, 218);
}
.search:focus {
  width: 100%;
}

.search::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
main {
  width: 100%;
  min-height: 91vh;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(239, 239, 239);
}
.productsContainer {
  display: flex;
  direction: column;
  justify-content: center;
  flex-wrap: wrap;
  background: transparent;
  width: 100%;
  min-height: 60vh;
  gap: 10px;
  margin-bottom: 40px;
}
.NavLink {
  text-decoration: none;
  height: 100%;
}
.card {
  position: relative;
  padding: 5px;
  width: 44%;
  height: 200px;
  background: #fff;
  border-radius: 5px;
  border: none;
  box-shadow: rgb(207, 207, 207, 50%) 2px 2px 5px;
  transition: box-shadow 0.2s ease-in-out;
  align-items: center;
  font-size: 0.8rem;
  color: rgb(49, 49, 49);
  font-weight: 700;
}
@media screen and (min-width: 600px) {
  .card {
    width: 18%;
    margin: 0 10px 10px 10px;
  }
}
.card:hover {
  box-shadow: rgb(207, 207, 207, 74%) 0 2px 5px 4px;
  cursor: pointer;
}

.show-more-container {
  display: flex;
  justify-content: center;
}
.favoritesContainer {
  min-height: 60vh;
}
.Favorite {
  position: absolute;
  right: 0;
  bottom: 15px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  padding: 15px;
}
.Favorite img {
  width: 20px;
  height: 20px;
}
h3 {
  text-align: center;
}

.products {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 1px 10px;
  background: #fff;
  font-size: smaller;
}

.product-detail {
  position: absolute;
  margin: 15px 0 5px 0;
  bottom: 5px;
}
.product_title {
  text-decoration: underline;
}
.imageContainer {
  margin: 5px auto auto auto;
  display: flex;
  justify-content: center;
  width: 90%;
  height: 60%;
}

.ProductImage {
  max-width: 130px;
  max-height: 130px;
}
.product-detail p {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 0.8rem;
}
.Price {
  color: rgb(239, 44, 0);
  font-size: 1rem;
}

.categoryContainer {
  display: flex;
  direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 90%;
  margin: 0 auto;
  gap: 15px;
}
.categoryContainer article {
  display: flex;
  justify-content: center;
  width: 45%;
  max-width: 150px;
  cursor: pointer;
  padding: 0 auto;
  color: rgb(49, 49, 49);
  font-weight: 700;
}
.categoryContainer article p {
  margin: auto;
  font-size: 1rem;
}
.categoryContainer article img {
  width: 30%;
  margin: 5px auto;
  background: rgb(255, 255, 255, 35%);
  border-radius: 15px;
}
h4 {
  color: rgb(255, 255, 255);
  text-shadow: #7f7f7f 0.7px 0.6px 0.5px;
  margin: 0;
  padding: 5px 0;
}

footer {
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*font-size: calc(10px + 2vmin);*/
  height: 4vh;
  color: #094375;
}
footer ul {
  background: #fff;
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 60px;
  padding: 0;
}
footer ul img {
  width: 2rem;
}
.Home {
  border-bottom: 5px #063773 solid;
}
.accountRegisterP {
  margin: 20px auto;
  align-self: center;
  font-size: 0.9rem;
  color: #063773;
}

.DisplayNone {
  display: none;
}
